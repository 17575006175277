<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Expatriate Practice Report 2020
      </h2>
      <img
        src="../../assets/img/tools_Expatriate_Practice_Report.jpg"
        alt="Expatriate Practice Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          Expatriate remuneration in Asia-Pacific has gone through much transformation.
          Companies are looking for effective ways to simplify expatriate package.
          Yet, under COVID-19 impact and ongoing economic instability,
          what is a competitive package?
        </p>
        <br>
        <p class="py-4">
            This survey report captures the latest expatriate remuneration trends in
            <u><strong>China, Hong Kong, Indonesia, Singapore and Vietnam, covering International
                Full Expatriate and Hybrid (Local-plus)</strong></u> respectively:
        </p>
        <br>
        <p class="py-4">
        Overview of report:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Benefit Practices: <span style="color:black;">Housing,
            Utility Subsidy, COLA,
              Hardship, Mobility, Tax, Children’s Education,
              Transportation, Car, Home Leave / Passage,
              Medical, Pension, Localisation</span></li>
          <li>Compensation Package Value: <span style="color:black;">
            (1) Basic Salary, (2) Fixed Allowance,
              (3) Short Term Incentive, (4) Perquisites</span></li>
        </ul>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
